export const projects = [
    {
        id: 1,
        link: "https://sites.google.com/view/shivams/ios/clipnow",
        imgSrc: "/assets/apps/clipNow.png",
        title: "ClipNow - Smart",
        isPremium: true,
        detailPage: {
            title: "ClipNow - Smart",
            logoText: "ClipNow - Smart Clipboard Enhancer App",
            appStoreLink: "https://apps.apple.com/in/app/clipnow-smart/id6575365762?mt=12",
            details: [
                {
                    title: "Unlock the full potential of your clipboard with Smart Clipboard App! Designed to enhance your productivity, this app offers a suite of powerful features",
                    description: ""
                },
                {
                    title: "Personal AI Writing Tool",
                    description: ""
                },
                {
                    title: "Instant Text Correction",
                    description: "Fix grammar, spelling, and punctuation with just a tap."
                },
                {
                    title: "Smart Code Enhancements",
                    description: "Optimize and generate code snippets effortlessly."
                },
                {
                    title: "Customizable Settings",
                    description: "Personalize grammar tone and select your preferred coding language."
                },
                {
                    title: "Quick Copy Feature",
                    description: "Use Cmd + Shift + V to instantly copy the enhanced text or code."
                },
                {
                    title: "Quick Paste",
                    description: "Use Cmd + V"
                },
                {
                    title: "",
                    description: "The Smart Clipboard App requires accessibility & input monitoring permissions to function seamlessly. These permissions are essential for providing you with the best clipboard management experience."
                }
            ],
            images: [
                {
                    imgId: 1,
                    image: "/assets/appDetails/clipnow/1.png",
                    isVertical: "true"
                }
            ]
        }
    },
    {
        id: 2,
        link: "https://sites.google.com/view/shivams/ios/sudoku-now",
        imgSrc: "/assets/apps/Sudoku.png",
        title: "Sudoku Now",
        isPremium: true,
        detailPage: {
            title: "Sudoku Now",
            logoText: "Sudoku Now - Challenge yourself everyday with personalized themes",
            appStoreLink: "https://apps.apple.com/ie/app/sudoku-now/id6541752301",
            details: [
                {
                    title: "Immerse yourself in the world of Sudoku with SudokuNow, where every puzzle is a new challenge waiting to be conquered!",
                    description: ""
                },
                {
                    title: "Play Puzzles",
                    description: "With a vast collection of puzzles ranging from easy to expert, there's always a new challenge to test your skills."
                },
                {
                    title: "Puzzle Hints",
                    description: "Stuck on a tough puzzle? Use hints and tips to guide you through tricky spots. But use them wisely!"
                },
                {
                    title: "Game Themes",
                    description: "Personalize your game with different themes and styles"
                },
                {
                    title: "Offline Mode",
                    description: "No internet? No problem! Play SudokuNow offline and keep your brain sharp wherever you are."
                },
                {
                    title: "No Ads",
                    description: "Enjoy a seamless, uninterrupted Sudoku experience with absolutely no ads."
                },
                {
                    title: "Community",
                    description: "Join the SudokuNow community today and elevate your puzzle-solving skills to new heights!"
                }
            ],
            images: [
                {
                    imgId: 1,
                    image: "/assets/appDetails/sudoku/1.png",
                    isVertical: "true"
                },
                {
                    imgId: 2,
                    image: "/assets/appDetails/sudoku/2.png",
                    isVertical: "true"
                },
                {
                    imgId: 3,
                    image: "/assets/appDetails/sudoku/3.png",
                    isVertical: "true"
                },
            ]
        }
    },
    {
        id: 3,
        link: "https://sites.google.com/view/shivams/ios/recipe-now",
        imgSrc: "/assets/apps/RecipeNow.png",
        title: "Recipe Now",
        isPremium: true,
        detailPage: {
            title: "Recipe Now",
            logoText: "Recipe Now - Scroll through tons of Recipes & Socialize",
            appStoreLink: "https://apps.apple.com/in/app/recipe-now/id6504085333?platform=iphone",
            details: [
                {
                    title: "RecipeNow",
                    description: "with tons of lip-smacking recipes beside you, excited and looking forward to tasting them."
                },
                {
                    title: "Search Recipes",
                    description: "There are lots of recipes to browse and decide for tonight."
                },
                {
                    title: "Global Post Feed",
                    description: "share your cooking experiences with the world, gain likes, comments, reviews and much more, don't forget to check this out."
                },
                {
                    title: "Grocery List",
                    description: "Plan your day or use it as a regular to-do list."
                },
                {
                    title: "Meal Planner",
                    description: "create plans and save your meal selection to the planner."
                },
                {
                    title: "BMI Calculator",
                    description: "for the health-conscious out there."
                },
                {
                    title: "Earn Cool Badges",
                    description: "There are several objectives once fulfilled, Badges are rewarded."
                },
                {
                    title: "Spin Wheel of Motivation",
                    description: "Get Cool Quotes as rewards upon the wheel stop."
                }
            ],
            images: [
                {
                    imgId: 1,
                    image: "/assets/appDetails/recipenow/1.png",
                    isVertical: "true"
                },
                {
                    imgId: 2,
                    image: "/assets/appDetails/recipenow/2.png",
                    isVertical: "true"
                },
                {
                    imgId: 3,
                    image: "/assets/appDetails/recipenow/3.png",
                    isVertical: "true"
                },
                {
                    imgId: 4,
                    image: "/assets/appDetails/recipenow/4.png",
                    isVertical: "true"
                },
            ]
        }
    },
    {
        id: 4,
        link: "https://sites.google.com/view/shivams/ios/tradetron",
        imgSrc: "/assets/apps/TradeTron.png",
        title: "TradeTron",
        isPremium: true,
        detailPage: {
            title: "TradeTron",
            logoText: "TradeTron - Manage your trade on the go",
            details: [
                {
                    title: "TradeTron",
                    description: "On alerts from TradingView, automate your buy-sell calls on indexes. Built with Python Flask with combination of IOS App to monitor remotely."
                },
                {
                    title: "Tracking",
                    description: "Track the Profit & Loss through the Web Python Flask App and iOS app running on the server based on ngrok."
                },
                {
                    title: "Automate/Manual",
                    description: "Buy Sell ITM, ATM, OTM on indexes manually or automate through TradingView platform."
                },
                {
                    title: "Trade Monitoring",
                    description: "No more hassle of monitoring your trades with our live trade monitoring feature, set the stoploss, set the target, and leave it on us."
                },
                {
                    title: "Lots of Settings",
                    description: "select stoploss, target, entry price, and the Cool feature Stoploss Trailing, or you want to break even with no loss, and trail from target, seems cool right."
                },
                {
                    title: "Cool Themes",
                    description: "Choose from a range of themes that prefers your style."
                }
            ],
            images: [
                {
                    imgId: 1,
                    image: "/assets/appDetails/tradetron/1.png",
                    isVertical: "true"
                },
                {
                    imgId: 2,
                    image: "/assets/appDetails/tradetron/2.png",
                    isVertical: "true"
                },
                {
                    imgId: 3,
                    image: "/assets/appDetails/tradetron/3.png",
                    isVertical: "true"
                },
                {
                    imgId: 4,
                    image: "/assets/appDetails/tradetron/4.png",
                    isVertical: "true"
                },
                {
                    imgId: 5,
                    image: "/assets/appDetails/tradetron/5.png",
                    isVertical: "true"
                }
            ],
            imagesWithText: [
                {
                    imgId: 6,
                    image: "/assets/appDetails/tradetron/6.png",
                    description: "TradingView (Automate the alerts)"
                },
                {
                    imgId: 7,
                    image: "/assets/appDetails/tradetron/7.png",
                    description: "Select your Index and get set go"
                },
                {
                    imgId: 8,
                    image: "/assets/appDetails/tradetron/8.png",
                    description: "Start your automate trading journey, with a Style with range of themes"
                },
                {
                    imgId: 9,
                    image: "/assets/appDetails/tradetron/9.png",
                    description: "Automatic trading with the webhook alerts from tradingView, and configure the settings"
                }
            ]
        }
    },
    {
        id: 5,
        link: "https://sites.google.com/view/shivams/ios/todo-now",
        imgSrc: "/assets/apps/ToDoNow.png",
        title: "ToDo Now",
        isPremium: false,
        detailPage: {
            title: "ToDo Now",
            logoText: "ToDo Now - Daily ToDo reminder for a boosted productivity",
            appStoreLink: "https://apps.apple.com/in/app/todo-now/id6503643447?platform=iphone",
            details: [
                {
                    title: "Task Reminders",
                    description: "Never forget important tasks with timely reminders."
                },
                {
                    title: "Artistic UI",
                    description: "Each note is daytime color based."
                },
                {
                    title: "Home Screen Widget",
                    description: "Add tasks directly from your home screen."
                },
                {
                    title: "Daily Schedule Mode",
                    description: "Plan your day or use it as a regular to-do list."
                },
                {
                    title: "Secure Notes",
                    description: "No server is involved; your notes stay secure on your device."
                }
            ],
            images: [
                {
                    imgId: 1,
                    image: "/assets/appDetails/todonow/1.png",
                    isVertical: "true"
                },
                {
                    imgId: 2,
                    image: "/assets/appDetails/todonow/2.png",
                    isVertical: "true"
                },
                {
                    imgId: 3,
                    image: "/assets/appDetails/todonow/3.png",
                    isVertical: "true"
                },
                {
                    imgId: 4,
                    image: "/assets/appDetails/todonow/4.png",
                    isVertical: "true"
                },
            ]
        }
    },
    {
        id: 6,
        link: "https://sites.google.com/view/shivams/ios/canigohome",
        imgSrc: "/assets/apps/CanIGoHome.png",
        title: "Can I Go Home",
        isPremium: false,
        detailPage: {
            title: "Can I Go Home",
            logoText: "Can I Go Home - Office Time Tracker for a healthy work life balance",
            appStoreLink: "https://apps.apple.com/in/app/can-i-go-home/id6476165409?platform=iphone",
            details: [
                {
                    title: "Work-Life Balance",
                    description: "Maintain work-life balance by reminding you to go home on time."
                },
                {
                    title: "Arrival Time Tracking",
                    description: "Automatically logs the time when you arrive at your set office location."
                },
                {
                    title: "Evening Go Home Notifications",
                    description: "Receive notifications as soon as you complete the required hours in office."
                },
                {
                    title: "Time Remaining Display",
                    description: "Check how much time remains until it's time to go home directly from the app."
                }
            ],
            images: [
                {
                    imgId: 1,
                    image: "/assets/appDetails/canigohome/1.png",
                    isVertical: "true"
                },
                {
                    imgId: 2,
                    image: "/assets/appDetails/canigohome/2.png",
                    isVertical: "true"
                },
                {
                    imgId: 3,
                    image: "/assets/appDetails/canigohome/3.png",
                    isVertical: "true"
                },
                {
                    imgId: 4,
                    image: "/assets/appDetails/canigohome/4.png",
                    isVertical: "true"
                },
            ]
        }
    },
    {
        id: 7,
        link: "",
        imgSrc: "/assets/apps/SkillManager.png",
        title: "Skill Manager",
        isPremium: true,
        detailPage: {
            title: "Skill Manager",
            logoText: "Skill Manager - Track your skills with style",
            appStoreLink: "https://apps.apple.com/in/app/skill-manager/id6743388647?platform=iphone",
            details: [
                {
                    title: "Add Skills",
                    description: "Easily add and manage the skills you want to improve or learn."
                },
                {
                    title: "Set Priorities",
                    description: "Assign custom weights to each skill based on how much focus they need."
                },
                {
                    title: "Time Calculation",
                    description: "Automatically calculate how much time to dedicate to each skill based on the priorities you've set."
                },
                {
                    title: "Track Progress",
                    description: "Mark skills as complete each day and stay on top of your personal growth journey."
                },
                {
                    title: "Dynamic Focus",
                    description: "Stay productive and organized by focusing more time on high-priority skills."
                },
            ],
            images: [
                {
                    imgId: 1,
                    image: "/assets/appDetails/skillmanager/1.png",
                    isVertical: "true"
                },
                {
                    imgId: 2,
                    image: "/assets/appDetails/skillmanager/2.png",
                    isVertical: "true"
                },
                {
                    imgId: 3,
                    image: "/assets/appDetails/skillmanager/3.png",
                    isVertical: "true"
                },
                {
                    imgId: 4,
                    image: "/assets/appDetails/skillmanager/4.png",
                    isVertical: "true"
                },
            ]
        }
    },
    {
        id: 8,
        link: "",
        imgSrc: "/assets/apps/mine.png",
        title: "Minesweeper Now",
        isPremium: true,
        detailPage: {
            title: "Minesweeper Now",
            logoText: "Minesweeper Now - Classic Minesweeper Fun",
            appStoreLink: "https://apps.apple.com/sk/app/minesweepernow/id6743623100?platform=iphone",
            details: [
                {
                    title: "Classic Gameplay",
                    description: "Experience the beloved Minesweeper mechanics you know and love. Flag mines, clear the grid, and conquer the game!"
                },
                {
                    title: "Flag Mode",
                    description: "Quickly mark those mines with intuitive flagging to help you navigate the board like a pro."
                },
                {
                    title: "Personalised Themes",
                    description: "Personalize your Minesweeper adventure with vibrant themes to suit your style."
                },
                {
                    title: "Smooth Animations & Sounds",
                    description: "Enjoy a polished experience with animations that bring the game to life and satisfying sounds for every action."
                },
                {
                    title: "Spin the fidget",
                    description: "Spin the fidget Icon on homescreen & distract the chaos of life."
                },
            ],
            images: [
                {
                    imgId: 1,
                    image: "/assets/appDetails/minesweeper/1.png",
                    isVertical: "true"
                },
                {
                    imgId: 2,
                    image: "/assets/appDetails/minesweeper/2.png",
                    isVertical: "true"
                },
                {
                    imgId: 3,
                    image: "/assets/appDetails/minesweeper/3.png",
                    isVertical: "true"
                },
                {
                    imgId: 4,
                    image: "/assets/appDetails/minesweeper/5.png",
                    isVertical: "true"
                },
            ]
        }
    }
];
// Not using link props as it directs to old website but now we have our own detail page in new website